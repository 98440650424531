import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Prescricao from './Prescricao/Prescricao';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import {Container, Row } from 'react-bootstrap';
import * as serviceWorker from './serviceWorker';

const routing = (
    <Router>
      <div>
        <Route exact path="/" component={Prescricao} />
        <Route path="/prescricao" component={Prescricao} />
      </div>
    </Router>
  );

const htmlStructure = (
    <Container fluid>
    <Row>
      {routing}
    </Row>
  </Container>
);

ReactDOM.render(htmlStructure, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
