/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Row, Col, FormControl, ButtonGroup, Button , Modal} from 'react-bootstrap';
import './Prescricao.css';
import dadosPrescricao from "./DadosPrescricao";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import "./Switch.css";
import Switch from 'react-toggle-switch'
import { LAUNCHSTAGE } from "../Shared/constants";

const StorageVariables = ['CPFMedico', 'CRMMedico', 'EstadoCRMMedico', 'token',
                         'NomeEstabelecimento', 'EndEstabelecimento', 'CEPEstabelecimento',  
                        'LogoEstabelecimento', 'launchStage' ];

export default class ModelsContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showModal: false,
        launchStage: LAUNCHSTAGE.HOMOLOG,
        token: "",
        CorPrimaria : "",
        CorSecundaria : "",
        LogoURL : "",
        loading: false,
        CPFMedico: "",
        CRMMedico: "",
        EstadoCRMMedico: "SP",
        NomePaciente: "",
        CPFPaciente: "",
        TelefonePaciente:"",
        url: "",
        TemBeneficio: false,
        NomeEstabelecimento: "",
        EndEstabelecimento: "",
        CEPEstabelecimento: "",
        LogoEstabelecimento:"",
        CertificadoDigitalObrigatorio: false,
      };
      this.initPrescricao = this.initPrescricao.bind(this);
      this.toggleSwitch = this.toggleSwitch.bind(this);
      this.certificadoSwitch = this.certificadoSwitch.bind(this);
      this.saveToLocalStorage = this.saveToLocalStorage.bind(this);
      this.loadFromLocalStorage();
      window.addEventListener("message", (event) => {
          const validEvents = ['cancel','excluded','prescricao'];
          if(validEvents.includes(event.data.type)) {
            this.setState({showModal: false});
            console.log("Retorno do evento:", event.data);
            this.saveToLocalStorage();
          }
      }, false);
    }

    componentWillMount(){
       // this.loadFromLocalStorage();
    }

    saveToLocalStorage(){
        StorageVariables.map(e => {
            return localStorage.setItem(e, this.state[e]);
        });
    }

    loadFromLocalStorage(){
        // eslint-disable-next-line
        StorageVariables.map(e => {
            console.log(e);
            console.log(localStorage.getItem(e));
            if(localStorage.getItem(e)){
                console.log('nroutr');
                // eslint-disable-next-line
                this.state[e] = localStorage.getItem(e);
                return this.setState({e: localStorage.getItem(e)});
            }            
        });
    }

    getModalUrlData() {
        let apiUrl;
        let modalUrl;
        
        const novaModalHabilitada = localStorage.getItem('novaPrescricao') === 'true'

        if (novaModalHabilitada) {          
            switch (this.state.launchStage) {
                case LAUNCHSTAGE.HOMOLOG:
                    apiUrl = "https://emr-homolog.nexodata.com.br/api";
                    modalUrl = "https://receitas-staging.nexodata.com.br/prescription";
                    break;
                case LAUNCHSTAGE.PROD:
                    apiUrl = "https://emr.nexodata.com.br/api";
                    modalUrl = "https://embedded.nexodata.com.br/prescricao";
                    break;
                case LAUNCHSTAGE.QA:
                    apiUrl = "https://emr-homolog.nexodata.com.br/api";
                    modalUrl = "http://qa-embedded.nexodata.com.br/prescricao";
                    break;
                default:
                    break;
            }
            return { apiUrl, modalUrl }
        }


        switch (this.state.launchStage) {
        case LAUNCHSTAGE.HOMOLOG:
            apiUrl = "https://emr-homolog.nexodata.com.br/api";
            modalUrl = "https://staging-embedded.nexodata.com.br/prescricao";
            break;
        case LAUNCHSTAGE.PROD:
            apiUrl = "https://emr.nexodata.com.br/api";
            modalUrl = "https://embedded.nexodata.com.br/prescricao";
            break;
        case LAUNCHSTAGE.QA:
            apiUrl = "https://emr-homolog.nexodata.com.br/api";
            modalUrl = "http://qa-embedded.nexodata.com.br/prescricao";
            break;
        default:
            break;
        }

        return {apiUrl, modalUrl}
    }

    async initPrescricao(){

       this.setState({ loading: true});
       const payload = dadosPrescricao;
       payload.CorPrimaria = this.state.CorPrimaria;
       payload.CorSecundaria = this.state.CorSecundaria;
       payload.LogoURL = this.state.LogoURL;
       payload.TemBeneficio = this.state.TemBeneficio;
       payload.CertificadoDigitalObrigatorio = this.state.CertificadoDigitalObrigatorio;
       payload.Medico.Documento = this.state.CPFMedico;
       payload.Medico.RegistroProfissional.Numero = this.state.CRMMedico;
       payload.Medico.RegistroProfissional.UF = this.state.EstadoCRMMedico;
        
       payload.Paciente.Nome = this.state.NomePaciente;
       payload.Paciente.Documento = this.state.CPFPaciente;
       payload.Paciente.TelefoneCelular = this.state.TelefonePaciente;

       payload.Estabelecimento.Nome = this.state.NomeEstabelecimento;
       payload.Estabelecimento.Endereco = this.state.EndEstabelecimento;
       payload.Estabelecimento.CEP = this.state.CEPEstabelecimento;
       payload.Estabelecimento.Logo = this.state.LogoEstabelecimento;
        

        let min = 1;
        let max = 100;
        let rand = min + (Math.random() * (max - min));
        console.log('rand', rand);
        payload.Paciente.ReferenciaExterna = rand.toString() ;
       console.log("Payload: ", payload);

       let {apiUrl, modalUrl} = this.getModalUrlData()

        try {
            const response = await axios.post(apiUrl+ "/prescricao/iniciar", payload, {
                headers: { Authorization: "Basic " + this.state.token }
            });
    
            this.setState({loading: false});
    
            //DEPOIS DE SE INICIAR UMA PRESCRIÇÃO ESSE É A INFORMAÇÃO QUE VOCE PRECISA PARA EXIBIR O MODAL
            const referencia = response.data.Referencia;
            console.log(response.data)
    
            modalUrl += `/${referencia}`;
            modalUrl += `/${this.state.token}`;
            this.setState({
                url: modalUrl,
                showModal: true
            });
        } 
        catch(err){
            alert(err);
            this.setState({loading: false});
        }

        console.log(modalUrl);
    }
    certificadoSwitch() {
        this.setState({ CertificadoDigitalObrigatorio: !this.state.CertificadoDigitalObrigatorio });
    }
    toggleSwitch(){
        this.setState({TemBeneficio: !this.state.TemBeneficio});
    }

    render() {
        return (
            <div style={{ 'padding': '2em' }}>          
                 <Row className="row-container">
                     <Col>
                        <h3>
                            Aplicação de exemplo de integração Nexodata Receitas
                        </h3>
                        <span>
                            Os campos obrigatórios são antecedidos por *
                        </span>
                     </Col>
                </Row>
                <Row className="row-container">
                    <Col xs='6'>
                    <h5>*Ambiente</h5>
                    </Col>               
                </Row>
                <Row className="row-container">
                    <Col xs='6'>
                        <ButtonGroup>
                        <Button
                            variant={
                            this.state.launchStage === LAUNCHSTAGE.HOMOLOG
                                ? "primary"
                                : "light"
                            }
                            onClick={() =>
                            this.setState({ launchStage: LAUNCHSTAGE.HOMOLOG })
                            }
                        >
                            Homologação
                        </Button>
                        <Button
                            variant={
                            this.state.launchStage === LAUNCHSTAGE.PROD
                                ? "primary"
                                : "light"
                            }
                            onClick={() => this.setState({ launchStage: LAUNCHSTAGE.PROD })}
                        >
                            Produção
                        </Button>
                        <Button
                            variant={
                            this.state.launchStage === LAUNCHSTAGE.QA
                                ? "primary"
                                : "light"
                            }
                            onClick={() => this.setState({ launchStage: LAUNCHSTAGE.QA })}
                        >
                            QA
                        </Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs='3'>
                        <span>*Token de Acesso:</span>
                        <FormControl
                            onChange={(e) => this.setState({ token: e.target.value })}
                            value={this.state.token}
                            style={{
                                'maxWidth': '300px'
                            }}
                            placeholder='token do parceiro'
                        />
                    </Col>
                </Row>
                <Row className="row-container">
                    <Col xs='6'>
                    <h5>Configurações</h5>
                    </Col>               
                </Row>
                <Row className="row-container">
                     <Col xs='4'>
                         <div>
                            <div>Assinatura digital obrigatória? </div>
                            <Switch onClick={this.certificadoSwitch} on={this.state.CertificadoDigitalObrigatorio} />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <div>Tem benefício? </div>
                            <Switch onClick={this.toggleSwitch} on={this.state.TemBeneficio} />
                        </div>
                    </Col>
                    <Col>
                        <span>Cor Primária:</span>
                        <FormControl
                            onChange={(e) => this.setState({ CorPrimaria: e.target.value })}
                            value={this.state.CorPrimaria}
                            style={{
                                'maxWidth': '100px'
                            }}
                            placeholder='#cccccc'
                        />
                    </Col>
                    <Col>
                        <span>Cor Secundária:</span>
                        <FormControl
                            onChange={(e) => this.setState({ CorSecundaria: e.target.value })}
                            value={this.state.CorSecundaria}
                            style={{
                                'maxWidth': '100px'
                            }}
                            placeholder='#c3c3c3'
                        />
                    </Col>
                    <Col>
                        <span>Logo Interface:</span>
                        <FormControl
                            onChange={(e) => this.setState({ LogoURL: e.target.value })}
                            value={this.state.LogoURL}
                            style={{
                                'maxWidth': '300px'
                            }}
                            placeholder='URL Absoluta'
                        />
                    </Col>
                </Row>
                <Row className="row-container">
                    <Col xs='6'>
                    <h5>*Dados do médico</h5>
                    </Col>               
                </Row>
                <Row className="row-container">
                    
                    <Col xs='3'>
                        <span>*CPF do médico</span>
                        <FormControl
                            onChange={(e) => this.setState({CPFMedico: e.target.value})}
                            value={this.state.CPFMedico}
                            style={{
                                'maxWidth': '150px'
                            }}
                            placeholder='CPF do médico'
                        />
                    </Col>
                    <Col xs='3'>
                        <span>*CRM do Médico:</span>
                        <FormControl
                            onChange={(e) => this.setState({ CRMMedico: e.target.value })}
                            value={this.state.CRMMedico}
                            style={{
                                'maxWidth': '150px'
                            }}
                            placeholder='CRM do médico'
                        />
                        </Col>
                    <Col xs='2'>
                        <span>*Estado:</span>
                        <FormControl as="select"
                            onChange={(e) => this.setState({ EstadoCRMMedico: e.target.value })}
                            value={this.state.EstadoCRMMedico}
                            style={{
                                'maxWidth': '100px'
                            }}
                            placeholder='Estado do CRM do médico'
                        >
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MT">MT</option>
                            <option value="MS">MS</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PR">PR</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                            </FormControl>
                    </Col>
                </Row>
                <Row className="row-container">
                    <Col xs='6'>
                    <h5>*Dados do paciente</h5>
                    </Col>               
                </Row>
                <Row className="row-container">

                    <Col xs='5'>
                        <span>*Nome do Paciente</span>
                        <FormControl
                            onChange={(e) => this.setState({ NomePaciente: e.target.value })}
                            value={this.state.NomePaciente}
                            style={{
                                'maxWidth': '300px'
                            }}
                            placeholder='Nome do Paciente'
                        />
                    </Col>
                    <Col xs='3'>
                        <span>*CPF do Paciente:</span>
                        <FormControl
                            onChange={(e) => this.setState({ CPFPaciente: e.target.value })}
                            value={this.state.CPFPaciente}
                            style={{
                                'maxWidth': '200px'
                            }}
                            placeholder='CPF do Paciente'
                        />
                    </Col>
                    <Col xs='4'>
                        <span>*Celular do Paciente:</span>
                        <FormControl
                            onChange={(e) => this.setState({ TelefonePaciente: e.target.value })}
                            value={this.state.TelefonePaciente}
                            style={{
                                'maxWidth': '200px'
                            }}
                            placeholder='Telefone do Paciente'
                        >
                        </FormControl>
                    </Col>
                </Row>
                <Row className="row-container">
                    <Col xs='6'>
                    <h5>Dados do estabelecimento</h5>
                    </Col>               
                </Row>
                <Row className="row-container">

                    <Col xs='5'>
                        <span>*Nome da Clínica / Hospital</span>
                        <FormControl
                            onChange={(e) => this.setState({ NomeEstabelecimento: e.target.value })}
                            value={this.state.NomeEstabelecimento}
                            style={{
                                'maxWidth': '300px'
                            }}
                            placeholder='Nome da Clínica / Hospital'
                        />
                    </Col>
                    <Col xs='4'>
                        <span>End. da Clínica / Hospital:</span>
                        <FormControl
                            onChange={(e) => this.setState({ EndEstabelecimento: e.target.value })}
                            value={this.state.EndEstabelecimento}
                            style={{
                                'maxWidth': '200px'
                            }}
                            placeholder='End. da Clínica / Hospital'
                        />
                    </Col>
                    <Col xs='3'>
                        <span>CEP:</span>
                        <FormControl
                            onChange={(e) => this.setState({ CEPEstabelecimento: e.target.value })}
                            value={this.state.CEPEstabelecimento}
                            style={{
                                'maxWidth': '150px'
                            }}
                            placeholder='CEP'
                        >
                        </FormControl>
                    </Col>
                </Row>


                <Row className="row-container">

                    <Col xs='5'>
                        <span>Logo da Clínica / Hospital</span>
                        <FormControl
                            onChange={(e) => this.setState({ LogoEstabelecimento: e.target.value })}
                            value={this.state.LogoEstabelecimento}
                            style={{
                                'maxWidth': '300px'
                            }}
                            placeholder='URL absoluta do Logo'
                        />
                    </Col>
                  
                </Row>
                <Row className="row-container">
                    <Col>
                        {
                        this.state.loading ?
                            <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={50}
                                width={50}
                                timeout={3000} //3 secs
                            />
                            :
                            <Button
                            onClick={this.initPrescricao}
                            >
                                Iniciar Prescrição
                            </Button>
                         }
                    </Col>
                </Row>
                {
                    this.state.showModal ?
                        <Modal 
                            dialogClassName='custom-dialog'
                            show={this.state.showModal}>
                            <Modal.Body style={{width:'100%',height:'90vh', padding:0}}>
                                <iframe
                                    width="100%" height="100%"
                                    src={this.state.url}
                                ></iframe>
                            </Modal.Body>
                        </Modal>
                        :
                        ''
                }
            </div>
        );
    }
}