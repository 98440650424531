//esse é o conjunto de dados recomendados para criação de uma prescrição
const dadosPrescricao = {
    "Medico":{
       "Especialidades":[
          "Anestesiologia",
          "Clínica Geral"
       ],
       "RegistroProfissional":{
          "Numero":"",
          "Conselho":"CRM",
          "UF":""
       },
       "Nome":"Medico Teste",
       "Documento":"",
       "Endereco":{
          "Endereco1":"Av. Angélica, 2529 - Bela Vista - São Paulo - SP, 01227-200"
       },
       "Sexo":"Masculino",
       "Email":"hugo.mendes@nexodata.com.br", 
    },
    "Paciente":{
       "Nome":"",
       "Nascimento":"2000-11-11T00:00:00",
       "Sexo": "M",
       "TelefoneCelular": "",
       "Email": "",
       "Endereco":{
          "Endereco1":"Av. Paulista, n 120",
          "Endereco2":"",
          "Cidade":"São Paulo",
          "Bairro":"Paulista",
          "Estado":"SP",
          "CodigoPostal":"13232131"
       },
       "Documento":"",
       "Alergias":[
          "DIPIRONA"
       ],
       "ReferenciaExterna":"",
       "Responsaveis":[
          {
             "GrauParentesco":"MAE",
             "Nome":"Creusa dos Santos",
             "Documento":"50595914098",
             "Endereco":{
                "Endereco1":"Av. Paulista, n 120",
                "Endereco2":"",
                "Cidade":"São Paulo",
                "Bairro":"Paulista",
                "Estado":"SP",
                "CodigoPostal":"13232131"
             },
             "TelefoneCelular":"123213131",
             "Email":"mae@terra.com.br",
             "Sexo":"Feminino"
          }
       ],      
    },
    "RegistroProntuarioEletronico":{
       "ReferenciaExterna":""
    },
    "Estabelecimento":{
       "Nome":"",
       "Endereco": "",
       "CEP":"123123",
       "Complemento":"",
       "Contato":""
    }
 };

 export default dadosPrescricao;